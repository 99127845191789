<template>
    <b-card title="Payment Adjustment">

        <b-row>
            <b-col md="12">
                <b-row>
                    <b-col md="3">
                        <b-form-group label="Store Name">
                            <v-select v-model="storeName" @input="selectStore" label="storeName" :options="storeNameOptions" />
                        </b-form-group>
                    </b-col>
                    <b-col md="3">
                        <b-form-group label="Payment Adjusted By">
                            <v-select v-model="paymentAdjustedBy" placeholder="Select Adjusted By" label="name"
                                :options="paymentAdjustedByOptions" />
                        </b-form-group>
                    </b-col>
                    <b-col md="3">
                        <b-form-group label="Payment Adjusted Type">
                            <v-select v-model="paymentAdjustedType" placeholder="Select Payment Adjusted Type" label="paymentSettlement"
                                :options="paymentAdjustedTypeOptions" />
                        </b-form-group>
                    </b-col>
                    <b-col md="3">
                        <b-form-group label="Order No">
                            <v-select :filterable="false" v-model="searchBoxForOrderNo" placeholder="Enter Order No"
                                    :options="customersOrderNo" @search="onInputChangeOnInvoice" @input="onSelectedOrderNo">
                                    <template #option="{ orderId, orderNo, customerName, customerId, customerMobileNo }">
                                        <h4># {{ orderNo }}</h4>
                                        <span>{{ customerName }} {{ '[' + customerMobileNo + ']' }} </span>
                                    </template>
                                </v-select>
                        </b-form-group>
                    </b-col>
                    <!-- <b-col md="3">
                <b-form-group label="Due Date">
                    <flat-pickr v-model="dueDate" class="form-control" name="date"
                        placeholder="Please Enter Due Date" />
                </b-form-group>
            </b-col> -->
                    <b-col md="3">
                        <b-form-group label="From Date">
                            <flat-pickr v-model="fromDate" class="form-control" name="date"
                                placeholder="Please Enter From Date" />
                        </b-form-group>
                    </b-col>
                    <b-col md="3">
                        <b-form-group label="To Date">
                            <flat-pickr v-model="toDate" class="form-control" name="date"
                                placeholder="Please Enter To Date" />
                        </b-form-group>
                    </b-col>
                    <b-col md="2">
                        <b-button block class="mt-2" variant="primary" @click="getPaymentAdjustmentList">Filter</b-button>
                    </b-col>
                </b-row>
                <hr>
                <div>
                    <!-- search input -->
                    <div class="custom-search d-flex justify-content-end">
                        <b-form-group>
                            <div class="d-flex align-items-center">
                                <label class="mr-1">Search</label>
                                <b-form-input v-model="searchTerm" placeholder="Search" type="text"
                                    class="d-inline-block" />
                            </div>
                        </b-form-group>
                    </div>

                    <!-- table -->
                    <vue-good-table :line-numbers="true" :columns="columns" :rows="rows"
                        :rtl="direction" :search-options="{
                            enabled: true,
                            externalQuery: searchTerm
                        }" :select-options="{
    enabled: false,
    selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
    selectionInfoClass: 'custom-class',
    selectionText: 'rows selected',
    clearSelectionText: 'clear',
    disableSelectInfo: true, // disable the select info panel on top
    selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
}" :pagination-options="{
    enabled: true,
    perPage: pageLength
}">
                        <template slot="table-row" slot-scope="props">

                            <!-- Column: lastModifiedDate -->
                            <span v-if="props.column.field === 'adjustmentDate'" class="text-nowrap">
                                <span class="text-nowrap">
                                    {{ convertTimeStampToDateWithTime(props.row.adjustmentDate) }}
                                    <!-- {{props.row.lastUpdated}} -->
                                </span>
                            </span>


                            <!-- Column: Common -->
                            <span v-else>
                                {{ props.formattedRow[props.column.field] }}
                            </span>
                        </template>

                        <!-- pagination -->
                        <template slot="pagination-bottom" slot-scope="props">
                            <div class="d-flex justify-content-between flex-wrap">
                                <div class="d-flex align-items-center mb-0 mt-1">
                                    <span class="text-nowrap ">
                                        Showing 1 to
                                    </span>
                                    <b-form-select v-model="pageLength" :options="['10', '20', '50', '100']"
                                        class="mx-1"
                                        @input="(value) => props.perPageChanged({ currentPerPage: value })" />
                                    <span class="text-nowrap"> of {{ props.total }} entries </span>
                                </div>
                                <div>
                                    <b-pagination :value="1" :total-rows="props.total" :per-page="pageLength"
                                        first-number last-number align="right" prev-class="prev-item"
                                        next-class="next-item" class="mt-1 mb-0"
                                        @input="(value) => props.pageChanged({ currentPage: value })">
                                        <template #prev-text>
                                            <feather-icon icon="ChevronLeftIcon" size="18" />
                                        </template>
                                        <template #next-text>
                                            <feather-icon icon="ChevronRightIcon" size="18" />
                                        </template>
                                    </b-pagination>
                                </div>
                            </div>
                        </template>
                    </vue-good-table>
                </div>
            </b-col>
        </b-row>
    </b-card>
</template>
<script>
import {
    BCard, BCol, BRow, BPagination, BFormSelect, BFormGroup, BFormInput, BTabs, BTab, BButton
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import api from '@/store/api'
import moment from 'moment'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'

export default {
    components: {
        BCard, BCol, BRow, VueGoodTable, BPagination, BFormSelect, BFormGroup, BFormInput, BTabs, BTab, vSelect,BButton,flatPickr
    },
    data() {
        return {
            pageLength: 10,
            dir: false,
            columns: [
                {
                    label: 'Store Name',
                    field: 'storeName'
                },
                {
                    label: 'Order No',
                    field: 'orderNo',
                },
                {
                    label: 'Adjustment Type',
                    field: 'paymentAdjustmentType'
                },
                {
                    label: 'Amount',
                    field: 'adjustmentAmount'
                },
                {
                    label: 'Adjustment Details',
                    field: 'adjustmentDetails'
                },
                {
                    label: 'Last Updated',
                    field: 'adjustmentDate',
                },
                {
                    label: 'Last Updated By',
                    field: 'adjustmentByEmp',
                    width: '300px'
                },
            ],
            rows: [],
            searchTerm: '',

            storeNameOptions: [],
            storeName: { 'id': this.$store.state.storeId, 'storeName': this.$store.state.storeName },
            paymentAdjustedBy: '',
            paymentAdjustedByOptions: [],
            paymentAdjustedType: '',
            paymentAdjustedTypeOptions: [],
            orderId:0,
            customersOrderNo:[],
            searchBoxForOrderNo:'',
            fromDate: null,
            toDate: null,
        }
    },
    
    methods: {
        convertTimeStampToDateWithTime(value) {
            if (value) {
                return moment.utc(value).local().format('DD/MM/YYYY hh:mm A');
            } else {
                return '';
            }
        },

        getPaymentAdjustmentList() {
            let self = this;

            var axios = require('axios');
            var data = '';
            let url='';

            let empId=0;

            if(self.paymentAdjustedBy.empId==undefined){
                empId=0;
            }else{
                empId=self.paymentAdjustedBy.empId;
            }

            let paymentAdjustedType=0;
            if(self.paymentAdjustedType.id==undefined){
                paymentAdjustedType=0;
            }else{
                paymentAdjustedType=self.paymentAdjustedType.id;
            }

            if(self.fromDate==null && self.toDate==null){
                url='/orders/getOrderPaymentAdjustmentDetails?empId=' + empId+ '&storeId=' + self.storeName.id + '&orderId=' + self.orderId + '&paymentAdjutTypeId=' + paymentAdjustedType;
            }else{
                url='/orders/getOrderPaymentAdjustmentDetails?empId=' + empId+ '&storeId=' + self.storeName.id + '&orderId=' + self.orderId + '&paymentAdjutTypeId=' + paymentAdjustedType;
            }
            alert(url)
            axios(api.getApi('get', url, data))
                .then(function (response) {
                    console.log(JSON.stringify(response.data))
                    self.rows = response.data;
                })
                .catch(function (error) {
                    alert(error)
                    console.log(error);
                });
        },
        getStoreName() {
            let self = this;
            var axios = require('axios');
            var data = '';
            axios(api.getApi('get', '/masters/stores', data))
                .then(function (response) {
                    //console.log(JSON.stringify(response.data));
                    self.storeNameOptions=response.data;

                    self.getEmployeesList();
                })
                .catch(function (error) {
                    console.log(error);
                });
        },
        selectStore(){
            this.getEmployeesList();
        },
        getEmployeesList() {

            let self = this;
            self.paymentAdjustedBy='';
            var axios = require('axios');
            var data = '';
            axios(api.getApi('get', '/getStoreEmployeesDetails/' + self.storeName.id + ',' + 0, data))
                .then(function (response) {
                    //console.log(JSON.stringify(response.data));
                    self.paymentAdjustedByOptions = response.data;
                })
                .catch(function (error) {
                    console.log(error);
                });
        },
        getPaymentAdjustmentType() {
            let self = this;
            var axios = require('axios');
            var data = '';
            axios(api.getApi('get', '/masters/paymentAdjustmentTypes', data))
                .then(function (response) {
                    //console.log(JSON.stringify(response.data));
                    self.paymentAdjustedTypeOptions = response.data;
                })
                .catch(function (error) {
                    console.log(error);
                });
        },
        onSelectedOrderNo(option) {
            // this.searchBoxForOrderNo = option.item;
            this.orderId = option.orderId;
            this.searchBoxForOrderNo = option.orderNo;
        },

        onInputChangeOnInvoice(orderNo) {
            if (orderNo === '' || orderNo === undefined) {
                return
            }
            let self = this;
            var axios = require('axios');
            var data = '';
            axios(api.getApi('get', '/orders/getOrdersFilteredListByOrderNoContaining/' + orderNo, data))
                .then(function (response) {
                    console.log(JSON.stringify(response.data));
                    if (response.data != '') {
                        self.customersOrderNo = response.data;
                    } else {
                        self.customersOrderNo = []
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });
        },


    },
    created(){
        this.getStoreName();
        // this.getEmployeesList();
        this.getPaymentAdjustmentType();
    }
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
<style lang="css">
@import '~vue-good-table/dist/vue-good-table.css';
</style>
<style>
.amountCredit {
    color: green;
}

.amountDebit {
    color: red;
}
</style>